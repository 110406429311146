<template>
  <div style="height: 800px; width: auto" v-html="poHtmlCode" />
</template>

<script>
const axios = require("axios");
export default {
  name: "Word",
  data() {
    return {
      message: " ",
      poHtmlCode: "",
      subject: "",
      userName: "",
      fileName: "",
      QRCodeUrl: "",
    };
  },
  created: async function () {
    /**
     * 
     * const parameters = {
        businessNumber: this.form.业务编号,
        client: this.form.委托方,
        projectName: this.form.项目名称,
        evaluateValue: this.form.评估总值,
        userId: this.currentUser.id,
        id: row.id,
      };
     * 
     */
    try {
      const obj = {
        businessNumber: this.$route.query.businessNumber,
        reportNumber: this.$route.query.reportNumber,
        client: this.$route.query.client,
        projectName: this.$route.query.projectName,
        evaluateValue: this.$route.query.evaluateValue,
        userId: this.$route.query.userId,
        id: this.$route.query.id,
      };

      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/readOnly`,
        method: "post",
        data: this.$decodeObject(obj),
      })
        .then(({ data }) => {
          this.poHtmlCode = data.msg;
        })
        .catch(function (err) {
          this.poHtmlCode = JSON.stringify(err);
        });
    } catch (error) {
      alert(JSON.stringify(error));
      this.poHtmlCode = JSON.stringify(error);
    }
  },
  mounted() {
    this.$addShowRevisionButton();
  }
};
</script>
